import { HttpContextToken } from '@angular/common/http';

export enum AuthorizationMethod {
  global_token,
  session_token,
  api_key
}

export interface Authorization {
  method: AuthorizationMethod;
  token: string;
}

export const AUTHORIZATION = new HttpContextToken(() => { return { method: AuthorizationMethod.session_token, token: '' }});
